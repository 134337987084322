// Font-family
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url(./Montserrat/Montserrat-Regular.ttf);
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url(./Montserrat/Montserrat-Medium.ttf);
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url(./Montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url(./Montserrat/Montserrat-Bold.ttf);
}

body {
    margin: 0;
    font-family: "Montserrat", "sans-serif" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Variables Colors
$white: #ffff;
$lightGray: #f2f5f8;
$mediumGray: #c3c5c8;
$gray: #707070;
$darkGray: #616374;
$lightBlue: #ced9e6;
$mediumBlue: #98a1ae;
$violet: #6614e1;
$dark: #231f20;
$black: #000;
$black80: rgba(0, 0, 0, 0.8);
$transparent: transparent;
// Variables min sizes
$none: none;
$auto: auto;
$solid: solid;
$v0: 0;
$v1: 1px;
$v2: 2px;
$v3: 3px;
$v4: 4px;
$v5: 5px;
$v6: 6px;
$v7: 7px;
$v8: 8px;
$v10: 10px;
$v15: 15px;
$v20: 20px;
$v25: 25px;
$v30: 30px;
$v40: 40px;
$v60: 60px;
$p1: 1%;
$p2: 2%;
$p3: 3%;
$p4: 4%;
$p5: 5%;
$p6: 6%;
$p7: 7%;
$p10: 10%;
$p15: 15%;
$p50: 50%;
$p60: 60%;
$p80: 80%;
$w1240: 1240px;
$w1400: 1400px;
// Variables Font-sizes
$fs10: 10px;
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs22: 22px;
$fs24: 24px;
$fs26: 26px;
$fs28: 28px;
$fs30: 30px;
$fs35: 35px;
$fs38: 38px;
$fs45: 45px;
// VariablesFont-weights
$fwRegular: 400;
$fwMedium: 500;
$fwSemiBold: 600;
$fwBold: 700;
// VariablesColumns-width
$cw16: calc(100% / 6);
$cw20: calc(100% / 5);
$cw25: calc(100% / 4);
$cw33: calc(100% / 3);
$cw50: calc(100% / 2);
$cw75: 75%;
$cw100: 100%;
$fit: fit-content;
//Position
$absolute: absolute;
$relative: relative;
// VariablesFlex
$row: row;
$column: column;
$stretch: stretch;
$center: center;
$start: flex-start;
$end: flex-end;
$justify-between: space-between;
$justify-around: space-around;
$left: left;
$right: right;
$o1: 1;
$o2: 2;
$o3: 3;
$o4: 4;
$o5: 5;
// Global Properties
body,
div {
    box-sizing: border-box;
}

// Predefined Properties for extend use
@mixin position($value) {
    position: $value;
}

@mixin background-image-center() {
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

@mixin image-url($value) {
    background-image: url($value);
}

@mixin background-color($value) {
    background-color: $value;
}

// COLOR
@mixin color($value) {
    color: $value;
}

// OPACITY
@mixin opacity($value) {
    opacity: $value;
}

// Filter for black img
@mixin filter() {
    filter: invert(15%) sepia(81%) saturate(4999%) hue-rotate(264deg) brightness(84%) contrast(112%);
}

@mixin hover-scale {
    transition: 0.15s all ease;
}

@mixin scale() {
    transform: scale(1.1);
    transition: 0.2s all ease;
}

@mixin pointer {
    cursor: pointer;
}

// Text-decoration
@mixin decoration($value) {
    text-decoration: $value;
}

@mixin uppercase() {
    text-transform: uppercase;
}

@mixin capitalize {
    text-transform: capitalize;
}

@mixin list() {
    list-style-type: $none;
    @include padding($v0);
    @include margin($v0);
}

@mixin text($value) {
    text-align: $value;
}

// Inputs
@mixin outline-none {
    outline: $none;
}

// FONT
@mixin font-size($value) {
    font-size: $value;
    line-height: $value;
}

@mixin font-weight($value) {
    font-weight: $value;
}

@mixin line($value) {
    line-height: $value;
}

@mixin border($value) {
    border: $value;
}

@mixin border-top($value) {
    border-top: $value;
}

@mixin border-bottom($value) {
    border-bottom: $value;
}

@mixin border-left($value) {
    border-left: $value;
}

@mixin border-right($value) {
    border-right: $value;
}

@mixin border-radius($value) {
    border-radius: $value;
}

@mixin box-shadow($value) {
    box-shadow: $value;
}

// Padding
@mixin padding($padding) {
    padding: $padding;
}

@mixin PaddingChangeAll() {
    @media only screen and (max-width: 1200px) {
        @include padding($v30);
    }
    @media only screen and (min-width: 1201px) {
        @include padding($v60);
    }
}

// Margin
@mixin margin($margin) {
    margin: $margin;
}

// Containers
@mixin container-full-padding {
    padding: $v20 $v60;
}

@mixin container-padding {
    padding: $v40 $p7;
}

@mixin container-min-width {
    max-width: $w1240;
    margin: $v0 $auto;
}

@mixin container-max-width {
    max-width: $w1400;
    margin: $v0 $auto;
}

@mixin max-width($value) {
    max-width: $value;
    margin: $v0 $auto;
}

// Columns
@mixin column($value) {
    width: $value;
}

@mixin row($value) {
    height: $value;
}

@mixin display-block {
    display: block;
}

// Flex Properties
@mixin display-flex {
    display: flex;
    flex-wrap: wrap;
}

@mixin direction($value) {
    flex-direction: $value;
}

@mixin justify($value) {
    justify-content: $value;
}

@mixin align($value) {
    align-items: $value;
}

@mixin order($value) {
    order: $value;
}

@mixin flex($value) {
    flex: $value;
}

body {
    @include background-color($lightGray !important);
}

.container-white {
    @include border-radius($v15);
    @include background-color($white);
    @include padding($v30);
}

// Global property
.section-max {
    @include container-max-width();
    @include display-flex();
}

.section-min {
    @include container-min-width();
    @include display-flex();
}

.button-svg {
    @include color($darkGray);
    @include display-flex();
    @include direction($row);
    @include align($center);
    @include border($v1 $solid $mediumGray);
    @include border-radius($v4);
    @include background-color($transparent);
    @include padding($v8 $v15);
    @include font-size($fs14);
    @include font-weight($fwSemiBold);
    svg {
        @include margin($v0 $v5 $v0 $v0);
    }
    &:hover {
        @include color($violet);
        @include border($v1 $solid $violet);
    }
}

.button {
    @include color($darkGray);
    @include border($none);
    @include border-radius($v4);
    @include background-color($transparent);
    @include padding($v8 $v15);
    @include font-size($fs12);
    @include font-weight($fwSemiBold);
    &:hover {
        @include background-color($lightGray);
    }
}

.button-violet {
    @include color($white);
    @include border($none);
    @include border-radius($v8);
    @include background-color($violet);
}

// Authentication Pages
// Login, logout, admin, signup, reset-password, forgot-password
.Form {
    @include padding($p5 $p3);
    @include container-min-width();
    > div {
        @include max-width(520px);
        @include border-radius($v20);
        @include box-shadow($v2 $v5 $v15 #00000026);
        @include background-color($lightGray);
        .title {
            @include border-bottom($v5 $solid $violet);
            h1 {
                @include font-size($fs20);
                @include font-weight($fwBold);
                @include color($violet);
                @include uppercase();
                @include margin($v0);
                @include padding($v20);
            }
        }
        form {
            @include padding($v20);
            > :first-child {
                @include margin($v0 $v0 $v10 $v0);
            }
            > p {
                // errors
                @include color($violet);
                @include font-size($fs14);
                @include font-weight($fwSemiBold);
                @include padding($v5 $v0);
                @include margin($v0);
            }
            .form-group {
                label {
                    @include font-size($fs14);
                    @include font-weight($fwSemiBold);
                    @include color($gray);
                    .req {
                        @include color($violet);
                        @include margin($v0 $v0 $v0 $v2);
                        @include font-weight($fwBold);
                    }
                }
                input {
                    @include font-weight($fwRegular);
                    @include font-size($fs16);
                    @include padding($v0);
                    @include border($none);
                    @include border-radius($v0);
                    @include outline-none();
                    @include border-bottom($v2 $solid $lightBlue);
                    @include background-color($transparent);
                    @include color($black);
                    &:focus {
                        @include box-shadow($none);
                        @include border-bottom($v2 $solid $violet);
                    }
                }
            }
        }
        .footer {
            @include padding($v0 $v20 $v20 $v20);
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include justify($justify-between);
            .forgot {
                button {
                    @include font-size($fs14);
                    @include color($gray);
                    @include font-weight($fwMedium);
                    @include background-color($transparent);
                    @include border($none);
                    &:hover {
                        @include color($violet);
                    }
                }
            }
            .submit {
                @include margin($v0 $v0 $v0 $auto);
                button {
                    @include font-size($fs14);
                    @include background-color($violet);
                    @include color($white);
                    @include padding($v5 $v15);
                    @include border($none);
                    @include font-weight($fwSemiBold);
                    @include border-radius($v8);
                    @include hover-scale();
                    &:hover {
                        @include scale();
                        @include color($white);
                    }
                }
            }
        }
    }
}

// HEADER
// Top bar
.topBar {
    @include padding($v15 $v60);
    @include background-color($white);
    @include display-flex();
    @include direction($row);
    overflow: hidden; /* HIDE SCROLL BAR */
    > div {
        p {
            @include font-size($fs12);
            @include line($fs24);
        }
    }
    .column1 {
        display: flex;
        p {
            @include font-weight($fwMedium);
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 10px;
            text-align: center;
            span:not(:first-child) {
                @include color($violet);
            }
        }
        p:not(:last-child) {
            @include margin($v0 $v20 $v0 $v0);
            span:first-child {
                @include margin($v0 $v5 $v0 $v0);
            }
        }
        p:last-child {
            @include margin($v0);
            span:not(:last-child) {
                @include margin($v0 $v5 $v0 $v0);
            }
        }
    }
    .column1 {
        animation: tickerh linear 25s infinite;
    }
    .column1:hover {
        animation-play-state: paused;
    }
    .column2 {
        // @include align($center);
        // @include justify($right);
        // @include column(20%);
        // p {
        //     @include font-weight($fwBold);
        // }
        // p:not(:last-child) {
        //     @include margin($v0 $v20 $v0 $v0);
        // }
        // p:last-child {
        //     @include margin($v0);
        // }
        display: none;
    }
}

@keyframes tickerh {
    0% {
        transform: translate3d(100vw, 0, 0);
    }
    100% {
        transform: translate3d(-70vw, 0, 0);
    }
}

@media only screen and (max-width: 600px) {
    .topBar {
        .column2 {
            margin: 0px;
            flex-direction: column;
            p:not(:last-child) {
                @include margin($v0 $v0 $v20 $v0);
            }
        }
    }
}

// Nav bar
//Burger menu responzive
.custom-nav {
    @include box-shadow($v0 $v0 $v1 $black);
    @include padding($v0 !important);
    @include background-color($white);
    .container-fluid {
        @include container-full-padding();
        @include display-flex();
        @include direction($row);
        @include align($center);
        .navbar-brand {
            @include padding($v0);
            @include column(112px);
        }
        .custom-menu {
            .nav-item {
                > a {
                    @include decoration($none);
                    @include color($black);
                    @include font-size($fs16);
                    @include font-weight($fwSemiBold);
                }
            }
        }
    }
}
@media (min-width: 1025px) {
    .navbar-expand-xl {
        .navbar-toggler {
            display: none;
        }
        .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
    }
    .custom-nav {
        .main-menu {
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include column(calc(100% - (245.5px * 2)));
            @include margin($v0 $v0 $v0 245.5px);
            @include justify($center);
        }
        .container-fluid {
            .navbar-brand {
                @include position($absolute);
                left: $v0;
                right: $v0;
                @include margin($v0 $auto);
            }
            .custom-menu {
                @include direction($row !important);
                .nav-item {
                    > a {
                        @include padding($v5);
                        &:hover {
                            @include border-bottom($v2 $solid $violet);
                        }
                    }
                }
                .nav-item:nth-child(2) {
                    @include margin($v0 87px $v0 $v20);
                }
                .nav-item:nth-child(3) {
                    @include margin($v0 $v20 $v0 87px);
                }
            }
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .custom-nav {
        .main-menu {
            .nav-item:nth-child(2) {
                margin: 0px 65px 0 10px !important;
            }
            .nav-item:nth-child(3) {
                margin: 0px 10px 0 65px !important;
            }
        }
    }
}

.authLinks {
    .main-auth {
        @include display-flex();
        @include direction($row);
        @include align($center);
        @include justify($end);
        @include column(245.5px);
        @include margin($v0 $v0 $v0 $auto);
        a[href="/signup"] {
            @include color($violet);
            @include padding($v8 $v10);
            @include margin($v0 $v0 $v0 15px);
            @include border($v1 $solid $violet);
            @include border-radius($v6);
            @include decoration($none);
            @include font-size($fs14);
            @include font-weight($fwSemiBold);
        }
        a[href="/login"] {
            @include color($black);
            @include decoration($none);
            @include font-size($fs14);
            @include font-weight($fwSemiBold);
            &:hover {
                @include color($violet);
            }
        }
    }
    .log-auth {
        display: flex;
        align-items: center;
        width: 245.5px;
        p {
            @include font-size($fs14);
            @include font-weight($fwSemiBold);
            @include color($darkGray);
            @include margin($v0 $v10 $v0 $v0);
        }
        div {
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include justify($justify-between);

            button {
                @include color($violet);
                @include padding($v8 $v10);
                @include margin($v0);
                @include border($v1 $solid $violet);
                @include border-radius($v6);
                @include decoration($none);
                @include font-size($fs14);
                @include font-weight($fwSemiBold);
                @include background-color($transparent);
                @include column(90px);
                margin-right: 20px
            }
            a {
                @include font-size($fs14);
                @include font-weight($fwSemiBold);
                @include color($black);
                @include padding($v0);
                @include decoration($none);
                &:hover {
                    @include color($violet);
                }
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
    .authLinks.desktop {
        display: none;
    }
}
@media only screen and (min-width: 1025px) {
    .nav-item {
        .authLinks {
            display: none;
        }
    }
}

// FOOTER
footer {
    @include image-url("../assets/Images/footer.png");
    @include background-image-center();
    @include container-full-padding();
    @include color($black);
    @include position($relative);
    :before {
        content: "";
        top: -1px;
        left: $p3;
        right: $p3;
        @include position($absolute);
        @include background-color($mediumGray);
        @include column(calc(100% - 6%));
        @include row($v1);
    }
    > div {
        @include container-min-width();
    }
    .footer-container {
        @include display-flex();
        @include direction($row);
        @include align($start);
        @include justify($justify-between);
        > div:not(:last-child) {
            @include padding($v0 $v15 $v0 $v0);
        }
        > div:first-child {
            img {
                @include column(65%);
            }
        }
        div {
            @include column($cw20);
            .title {
                @include margin($v0 $v0 $v5);
                @include font-size($fs16);
                @include font-weight($fwSemiBold);
            }
            ul {
                @include list();
                li {
                    a {
                        @include display-flex();
                        @include direction($row);
                        @include align($center);
                        @include font-size($fs14);
                        @include decoration($none);
                        @include color($black80);
                        @include font-weight($fwMedium);
                        @include line(26px);
                        > span {
                            @include margin($v0 $v5 $v0 $v0);
                            @include line($v30);
                        }
                        > img {
                            @include column($v20);
                            @include hover-scale();
                        }
                        &:hover {
                            @include color($violet);
                            > img {
                                @include scale();
                                @include filter();
                            }
                        }
                    }
                }
            }
        }
    }
    .bottomBar {
        @include padding($v20 $v0 $v0);
        p {
            @include margin($v0);
            @include font-size($fs16);
            @include color($black80);
            @include font-weight($fwMedium);
        }
    }
}
@media only screen and (max-width: 380px) {
    footer {
        @include padding($v20 !important);
        .footer-container {
            > div:first-child {
                text-align: center;
                a {
                    @include column($fit);
                    @include margin($v0 $auto);
                }
            }
            > div:not(:first-child) {
                @include column(100% !important);
                a {
                    display: block;
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    footer {
        @include padding($v20 !important);
        .footer-container {
            > div:first-child {
                @include padding($v0 !important);
                @include column(100% !important);
                img {
                    @include column(150px !important);
                }
            }
            > div:not(:first-child) {
                @include column(50% !important);
                @include padding($v0 !important);
                @include margin($v20 $v0 $v0 $v0);
            }
        }
    }
}

// HOME
// general wraper for the home page
.crypto-box {
    .main-container {
        @include padding($v60);
        .price-general {
            @extend .section-max;
        }
        .first {
            @extend .section-max;
            @include direction($row);
            @include padding($v0 $v0 $v60 $v0);
            @include align($stretch);
            .news {
                @extend .container-white;
                @include column(calc(100% - 420px - 60px));
                @include margin($v0 $v30 $v0 $v0);
                @include display-flex();
                @include align($center);
                ul {
                    @include display-flex();
                    @include direction($column);
                    @include justify($justify-between);
                    @include list();
                    li:not(:last-child) {
                        @include margin($v0 $v0 $v30);
                    }
                    li {
                        a {
                            @include display-flex();
                            @include direction($row);
                            @include align($center);
                            @include decoration($none);
                            > div:first-child {
                                @include margin($v0 $v20 $v0 $v0);
                                > img {
                                    @include border-radius($v10);
                                    @include column(250px);
                                    @include row(150px);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                            > div:last-child {
                                @include flex($o1);
                                @include color($black);
                                .title {
                                    @include font-size($fs18);
                                    @include font-weight($fwBold);
                                    @include line($v20);
                                    @include margin($v0 $v0 $v10 $v0);
                                }
                                .excerpt {
                                    @include font-size($fs14);
                                    @include line($fs18);
                                    @include font-weight($fwMedium);
                                    @include margin($v0);
                                }
                            }
                        }
                    }
                }
                > div {
                    @include margin($v20 $v0 $v0);
                    p:first-child {
                        @include font-size($fs20);
                        @include font-weight($fwBold);
                        @include margin($v0 $v0 $v10 $v0);
                        @include line($v20);
                    }
                    p:last-child {
                        @include font-size($fs14);
                        @include font-weight($fwRegular);
                        @include margin($v0);
                        a {
                            @include decoration($none);
                            @include color($violet);
                            &:hover {
                                @include color($mediumGray);
                            }
                        }
                    }
                }
            }
            .home-tabs {
                @include column($fit);
                @include margin($v0 $v0 $v0 $v30);
                // @extend .container-white;
            }
        }
        .second {
            @extend .section-max;
            @include direction($column);
            @extend .container-white;
            margin-bottom: 60px;
            overflow-x: scroll;
            // PAGINATION
            .details {
                @include padding($v10 $v0 $v0);
                @include position($relative);
                @include display-flex();
                @include justify($center);
                > p {
                    @include font-size($fs12);
                    @include color($black);
                    @include font-weight($fwRegular);
                    @include margin($v0);
                    @include position($absolute);
                    bottom: 0;
                    left: 0;
                    @include padding($v7 $v0);
                }
                ul.pagination {
                    @include margin($v0);
                    .page-item.active {
                        .page-link {
                            @include background-color($transparent !important);
                            @include border($none !important);
                            @include color($violet !important);
                        }
                    }
                    li {
                        a.page-link {
                            @include border($none !important);
                            @include color($black);
                            @include font-size($fs14 !important);
                            @include font-weight($fwSemiBold !important);
                            @include background-color($transparent !important);
                            @include padding($v7 $v10);
                            &:hover {
                                @include color($violet !important);
                            }
                        }
                    }
                }
            }
        }
        .third {
            @extend .section-max;
            @include direction($column);
            @include padding($v60 $v0 $v0);
            > div:first-child {
                @include background-color($white);
                @include border-radius($v15 $v15 $v0 $v0);
                p {
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                    @include margin($v0);
                    @include padding($v30);
                }
            }
            .faq {
                @include display-flex();
                @include direction($row);
                @include border-top($v2 $solid $lightGray);
                @include border-radius($v0 $v0 $v15 $v15);
                // @include border-bottom($v2 $solid $lightGray);
                @include background-color($white);
                .nav.nav-tabs {
                    @include column($cw50);
                    @include order($o2);
                    @include display-flex();
                    @include direction($column);
                    @include padding($v30);
                    @include border-left($v1 $solid $lightGray);
                    @include border-bottom($v0 $solid $transparent);
                    .nav-item {
                        @include margin($v0 $v0 $v10 $v0);
                        button {
                            @include border($none);
                            @include padding($v0);
                            @include color($black);
                            @include font-size($fs16);
                            @include font-weight($fwMedium);
                            @include line(24px);
                            text-align: left;
                            &:hover {
                                @include color($violet);
                            }
                            > div {
                                @include display-flex();
                                @include direction($row);
                                svg {
                                    @include margin($v0 $v10 $v0 $v0);
                                    @include font-size($fs22);
                                }
                                span {
                                    @include column(calc(100% - 32px));
                                }
                            }
                        }
                        button.active {
                            @include color($violet);
                        }
                        // button:not(:last-child) {
                        //     @include margin($v0 $v0 $v5 $v0);
                        // }
                    }
                }
                .tab-content {
                    @include column($cw50);
                    @include order($o1);
                    @include padding($v30);
                    @include border-right($v1 $solid $lightGray);
                    .title {
                        @include color($black);
                        @include font-size($fs22);
                        @include font-weight($fwSemiBold);
                    }
                    p {
                        @include color($black);
                        @include font-size($fs14);
                        @include line(26px);
                    }
                    p:not(:last-child) {
                        @include margin($v0 $v0 $v20 $v0);
                    }
                    p:last-child {
                        @include margin($v0);
                    }
                }
            }
        }
    }
    .subscribe {
        @include margin($v0 $v0 $v60);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include display-flex();
        @include direction($row);
        @include padding($v60);
        justify-content: space-between;
        align-items: center;
        max-width: 1400px;
        margin: $v60 auto;
        background-color: #ffff;
        > div {
            @include column($cw50);
        }
        div:first-child {
            @include display-flex();
            @include align($left);
            @include justify($center);
            @include direction($column);
            .title {
                @include font-size($fs30);
                @include font-weight($fwRegular);
                @include margin($v0);
                span {
                    @include font-weight($fwBold);
                }
            }
            .sub-title {
                @include font-size($fs18);
                @include margin($v0);
                @include line(32px);
            }
            form {
                @include padding($v20 $v0);
                @include display-flex();
                @include direction($row);
                @include align($center);
                input[type="email"] {
                    @include border($none);
                    @include padding($v10);
                    @include background-color($lightGray);
                    @include outline-none();
                    @include column($cw50);
                    @include border-radius($v4);
                    @include margin($v0 $v20 $v0 $v0);
                    @include font-size($fs14);
                    @include color($violet);
                    @include font-weight($fwMedium);
                }
                input[type="submit"] {
                    @extend .button-svg;
                    @include border($v1 $solid $violet);
                    @include color($violet);
                    @include font-size($fs18);
                    @include font-weight($fwMedium);
                    @include hover-scale();
                    &:hover {
                        @include scale();
                    }
                }
            }
        }
        div:last-child {
            @include display-flex();
            @include align($end);
            @include justify($end);
            width: 380px;
            height: 236px;
            img {
                @include column(100%);
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .subscribe {
        > div {
            width: 100% !important;
            form {
                flex-direction: column !important;
                align-items: flex-start !important;
                input:first-child {
                    width: 167px !important;
                    margin-bottom: 20px !important;
                }
                input:last-child {
                    width: 167px !important;
                    margin-bottom: 20px !important;
                }
            }
        }
        div:last-child {
            width: 380px !important;
            height: 236px !important;
            margin: auto;
            img {
                @include column(100%);
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
    .crypto-box {
        .main-container {
            @include padding(25px !important);
            .first {
                .news {
                    @extend .container-white;
                    @include column(100%);
                    @include margin($v0 $v0 50px $v0);
                    @include display-flex();
                    @include align($center);
                }
                .home-tabs {
                    @include column(100%);
                    @include margin($v0 $v0 $v0 $v0);
                    // @extend .container-white;
                    .tab-box {
                        width: 100%;
                        .nav-tabs {
                            justify-content: flex-start;
                            .nav-item:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .third {
                @extend .section-max;
                @include direction($column);
                @include padding($v60 $v0 $v0);
                .faq {
                    @include display-flex();
                    @include direction($row);
                    @include border-top($v2 $solid $lightGray);
                    @include border-radius($v0 $v0 $v15 $v15);
                    // @include border-bottom($v2 $solid $lightGray);
                    @include background-color($white);
                    .nav-tabs {
                        width: 100% !important;
                        order: 1;
                    }
                    .tab-content {
                        width: 100% !important;
                        order: 2;
                    }
                }
            }
        }
        .subscribe {
            > div {
                form {
                    input:first-child {
                        width: 167px !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
            div:first-child {
                width: 42%;
            }
        }
    }
}
// todays price componen, the part on the page after the blog
.TodaysPrices {
    @include column($cw100);
    @include margin($v0 $v0 $v60);
    @include background-color($white);
    @include border-radius($v15);
    .title {
        @include padding($v30);
        p {
            @include font-size($fs24);
            @include font-weight($fwBold);
            @include margin($v0);
        }
    }
    .box-container {
        @include display-flex();
        @include direction($row);
        .small-box {
            @include column($cw16);
            @include padding($v15);
            @include border-top($v2 $solid $lightGray);
            .box-title {
                @include font-size($fs14);
                @include font-weight($fwMedium);
                @include margin($v0);
            }
            .box-content {
                @include margin($v15 $v0 $v0);
                @include display-flex();
                @include direction($row);
                @include align($start);
                .small-container {
                    @include column(calc(100% - 40px));
                    .duration {
                        @include uppercase();
                        @include font-size($fs10);
                        @include margin($v0);
                        @include font-weight($fwBold);
                        @include color($darkGray);
                        @include row($v10);
                    }
                    .specifications {
                        @include display-flex();
                        @include direction($row);
                        @include align($start);
                        @include justify($justify-between);
                        @include margin($v10 $v0);
                        > p {
                            @include font-size($fs14);
                            @include font-weight($fwSemiBold);
                            @include margin($v0);
                        }
                        > p:last-child {
                            svg {
                                @include margin($v0 $v5 $v0 $v0);
                            }
                        }
                    }
                    .specifications-s {
                        @include padding($v5 $v0 $v0);
                        p {
                            @include font-size($fs14);
                            @include font-weight($fwSemiBold);
                            @include margin($v0);
                        }
                    }
                    .range-s {
                        @include position($relative);
                        @include column($cw100);
                        @include row($v6);
                        > span {
                            @include row($v6);
                            @include position($absolute);
                            @include border-radius($v6);
                        }
                        > span:first-child {
                            @include background-color($violet);
                            z-index: $o1;
                        }
                        > span:last-child {
                            @include background-color($lightBlue);
                            z-index: $v0;
                        }
                    }
                }
                .icon {
                    @include position($relative);
                    @include column($fit);
                    @include margin($v0 $v15 $v0 $v0);
                    img {
                        @include column(25px);
                    }
                    svg {
                        @include font-size($fs20);
                        @include color($darkGray);
                    }
                    svg.rotate-down {
                        @include position($absolute);
                        transform: rotateZ(-53deg);
                        right: $v1;
                        top: -12px;
                        @include font-size(28px);
                    }
                    svg.rotate-up {
                        @include position($absolute);
                        transform: rotateZ(55deg);
                        left: $v1;
                        top: -12px;
                        @include font-size(28px);
                    }
                    svg.rotate {
                        transform: rotateY(180deg);
                    }
                }
            }
        }
        .small-box:not(:last-child) {
            @include border-right($v2 $solid $lightGray);
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .TodaysPrices {
        .box-container {
            @include display-flex();
            @include direction($row);
            .small-box {
                width: 33%;
            }
        }
    }
}
@media only screen and (max-width: 599px) {
    .TodaysPrices {
        .box-container {
            @include display-flex();
            @include direction($row);
            .small-box {
                width: 50%;
            }
        }
    }
}
// box component, the part on the page where all the crypto is listed
.box {
    .box-head {
        @include padding($v20 $v0);
        @include display-flex();
        @include direction($row);
        @include align($center);
        > div:first-child {
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include padding($v0 $v20 $v0 $v0);
            @include border-right($v2 $solid $mediumGray);
            a:first-child {
                @include margin($v0 $v10 $v0 $v0);
            }
            >div {
                margin-right: 12px;
                @include column(120px);
                position: relative;
                input {
                    @extend .button-svg;
                    @include column(100%);
                    font-size: 12px;
                    height: 12px;
                }
                .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                    top: -12px;
                    left: 15px
                }
                .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
                    left: 19px;
                    top: -5px;
                }
                .search-listing {
                    position: absolute;
                    list-style-type: none;
                    padding: 15px 5px;
                    background-color: white;
                    font-size: 12px;
                    width: 210px;
                    >li {
                        padding: 5px 0px;
                        .logo {
                            @include column(23px);
                            margin-right: 15px;
                        }
                        >a {
                            color: #616374;
                            text-decoration: none;
                            font-weight: 500;
                        }
                    }
                }
            }
            >a {
                @extend .button-svg;
                @include decoration($none);
            }
            >a:not(:last-child) {
                margin-right: 10px
            }
        }
        .box-menu {
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include padding($v0 $v10 $v0 $v20);
            button {
                @extend .button;
            }
            button:not(:last-child) {
                @include margin($v0 $v10 $v0 $v0);
            }
            button.active {
                @include background-color($lightGray);
            }
        }
        > div:last-child {
            @include display-flex();
            @include direction($row);
            @include align($center);
            @include margin($v0 $v0 $v0 $auto);
            form {
                @include margin($v0 $v10 $v0 $v0);
                label {
                    @include display-flex();
                    @include direction($row);
                    @include align($center);
                    @include font-size($fs12);
                    @include font-weight($fwSemiBold);
                    select {
                        @extend .button-svg;
                        @include margin($v0 $v0 $v0 $v10);
                        @include padding($v5 $v0);
                    }
                }
            }
            button {
                @extend .button-svg;
            }
        }
    }
    .box-table {
        @include column($cw100);
        table-layout: auto;
        overflow-x: auto;
        thead {
            @include color($black);
            @include font-size($fs14);
            @include text($center);
            tr {
                border-top-left-radius: $v10;
                border-bottom-left-radius: $v10;
                @include box-shadow($v0 $v3 $v6 #00000033);
                border-top-right-radius: $v10;
                border-bottom-right-radius: $v10;
                th {
                    @include background-color($lightGray);
                    @include padding($v15 $v5);
                    @include font-weight($fwSemiBold);
                    @include font-size($fs12);
                }
                th:first-child {
                    border-top-left-radius: $v10;
                    border-bottom-left-radius: $v10;
                }
                th:last-child {
                    border-top-right-radius: $v10;
                    border-bottom-right-radius: $v10;
                }
                th:nth-last-child(-n + 7) {
                    @include border-left($v1 $solid #c3c5c870);
                }
                th:nth-child(-n + 3) {
                    left: 0;
                }
            }
        }
        tbody {
            tr:nth-child(even) {
                @include background-color(#f2f5f859);
            }
            tr {
                @include border-bottom($v1 $solid #c3c5c870);
                td {
                    @include padding($v15 $v5);
                    @include font-weight($fwSemiBold);
                    @include font-size($fs12);
                    @include color($black);
                    white-space: nowrap;
                }
                td:nth-last-child(-n + 7) {
                    @include border-left($v1 $solid #c3c5c870);
                    @include text($center);
                }
                td:nth-child(-n + 2) {
                    @include text($center);
                }
                td:nth-child(-n + 3) {
                    left: 0;
                }
                .favorite {
                    @include color($mediumGray);
                    &:hover {
                        @include color($violet);
                        @include pointer();
                    }
                }
                .favorite-fill {
                    @include color($violet);
                    &:hover {
                        @include pointer();
                    }
                }
                .cripto {
                    > div {
                        @include display-flex();
                        @include align($center);
                        .logo {
                            @include column($v25);
                            @include row($v25);
                            img {
                                @include column($v25);
                            }
                        }
                        span:not(:last-child) {
                            @include margin($v0 $v5 $v0 $v0);
                        }
                        a {
                            @include decoration($none);
                            > span:first-child {
                                @include color($black);
                            }
                            > span:last-child {
                                @include color($gray);
                            }
                            &:hover {
                                > span:first-child {
                                    @include color($violet);
                                }
                            }
                        }
                    }
                }
                .chart {
                    @include column(210px);
                    > a {
                        display: block;
                        .recharts-wrapper {
                            margin: 0 !important;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

label[data-shrink="true"] + ul.search-listing {
    display: block !important;
}

@media only screen and (max-width: 1270px) {
    .box {
        .box-head {
            >div:first-child {
                padding-bottom: 20px;
                border: 0px;
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 1470px) {
    .second {
        padding-top: 0px !important;
        .box {
            .box-head {
                >div:first-child {
                    padding-bottom: 20px;
                    border: 0px;
                    flex-direction: column;
                    padding-bottom: 0px;
                    >div {
                        margin-right: 0px;
                        padding-bottom: 12px;
                    }
                    >a:not(:last-child) {
                        margin-right: 0px;
                        margin-bottom: 12px;
                    }
                }
                .box-menu {
                    padding-left: 0px;
                    .button {
                        margin: 0px !important;
                        padding-right: 12px !important;
                    }
                }
            }
        }
    }
}

// currencies
// the first part of the page
.coin-banner {
    padding: 60px;
    > div {
        @include container-max-width();
        @include background-color($white);
        @include border-radius($v15);
        > .breadcrumbs {
            @include padding($v30);
            @include color($gray);
            @include capitalize();
            @include font-size($fs14);
            @include font-weight($fwSemiBold);
            a {
                @include decoration($none);
                @include color($gray);
            }
            span:last-child {
                @include color($black);
            }
            svg {
                @include margin($v0 $v5);
            }
        }
        > .first {
            @include display-flex();
            @include direction($row);
            @include padding($v0 $v30);
            @include align($center);
            @include justify($justify-between);
            > div:first-child {
                @include margin(-5px);
                @include color($white);
                @include font-size($fs14);
                @include capitalize();
                span {
                    @include background-color($darkGray);
                    @include padding($v5 $v10);
                    @include margin($v5);
                    @include border-radius($v4);
                }
            }
            > div:last-child {
                @include display-flex();
                @include direction($row);
                @include margin(-5px);
                button {
                    @extend .button-svg;
                    @include margin($v5);
                    svg {
                        @include margin($v0 $v0 $v0 $v5);
                    }
                }
            }
        }
        .coin-details {
            @include display-flex();
            @include direction($row);
            .info-holder {
                @include column(calc(100% - 310px));
                .page-info {
                    @include padding($v30);
                    .nav-tabs {
                        @include border($v0 $solid $transparent);
                        @include margin(-5px);
                        .nav-item {
                            @include background-color($lightGray);
                            @include margin($v5);
                            .nav-link.active {
                                @include border($v1 $solid $violet);
                                > div {
                                    @include color($violet);
                                }
                            }
                            > button {
                                @include border($v1 $solid $transparent);
                                @include padding($v0);
                                @include border-radius($v4);
                                @include outline-none();
                                > div {
                                    @include padding($v8 $v10);
                                    @include font-size($fs14);
                                    @include color($gray);
                                    > *:first-child {
                                        @include margin($v0 $v5 $v0 $v0);
                                    }
                                    span {
                                        @include font-weight($fwSemiBold);
                                    }
                                    > svg:last-child {
                                        @include margin($v0 $v0 $v0 $v5);
                                    }
                                }
                            }
                            &:hover,
                            &:focus {
                                @include background-color($white);
                                > button {
                                    @include border($v1 $solid $violet);
                                    > div {
                                        @include color($violet);
                                    }
                                }
                            }
                        }
                    }
                    .tab-content {
                        .tab-pane.active {
                            @include padding($v15 $v0 $v0 $v0);
                            @include display-flex();
                            @include direction($column);
                            a:not(:last-child) {
                                @include margin($v0 $v0 $v10 $v0);
                            }
                            a {
                                @include font-size($fs14);
                                @include decoration($none);
                                @include color($black);
                                @include column($fit);
                                @include font-weight($fwSemiBold);
                                &:hover {
                                    @include color($violet);
                                }
                                > svg {
                                    @include margin($v0 $v10 $v0 $v0);
                                    @include font-size($fs12);
                                }
                            }
                        }
                    }
                }
                .stats-info {
                    @include column($cw100);
                    @include display-flex();
                    @include direction($row);
                    > div {
                        @include padding($v30);
                        @include column($cw25);
                        .title {
                            @include margin($v0 $v0 $v10 $v0);
                            span {
                                @include color($darkGray);
                                @include font-size($fs14);
                                @include font-weight($fwSemiBold);
                            }
                            svg {
                                @include color($mediumBlue);
                                @include margin($v0 $v0 $v0 $v5);
                            }
                        }
                        .value {
                            @include margin($v0);
                            span {
                                @include font-weight($fwSemiBold);
                                @include font-size($fs14);
                            }
                            span:first-child {
                                @include color($black);
                            }
                            span:last-child {
                                display: none;
                            }
                        }
                        > div {
                            @include display-flex();
                            @include direction($row);
                            @include justify($justify-between);
                            p {
                                @include margin($v0);
                                @include font-size($fs14);
                            }
                            p:first-child {
                                @include color($black);
                                @include font-weight($fwSemiBold);
                            }
                            svg:last-child {
                                @include color($darkGray);
                            }
                            p:last-child {
                                @include color($darkGray);
                                @include font-weight($fwMedium);
                            }
                        }
                        .max,
                        .total {
                            @include display-flex();
                            @include direction($row);
                            @include margin($v0);
                            @include align($center);
                            span:first-child {
                                @include color($darkGray);
                                @include font-size($fs14);
                                @include font-weight($fwSemiBold);
                            }
                            span {
                                svg {
                                    @include color($mediumBlue);
                                    @include margin($v0 $v0 $v0 $v5);
                                }
                            }
                            span:last-child {
                                @include margin($v0 $v0 $v0 $auto);
                                @include color($black);
                                @include font-weight($fwSemiBold);
                                @include font-size($fs14);
                            }
                        }
                    }
                }
            }
            .price-info {
                @include padding($v30);
                @include column(310px);
                .second {
                    .coin-details {
                        @include column(calc(100% - 70px));
                    }
                    div:first-child {
                        @include display-flex();
                        @include direction($row);
                        @include align($center);
                        > *:not(:last-child) {
                            @include margin($v0 $v10 $v0 $v0);
                        }
                        > *:last-child {
                            @include margin($v0);
                            @include padding($v0);
                        }
                        img {
                            @include column($v60);
                        }
                        p {
                            @include font-size($fs24);
                            @include font-weight($fwBold);
                        }
                        p:nth-child(3) {
                            @include color($gray);
                        }
                        button {
                            @include background-color($transparent);
                            @include outline-none();
                            @include border($none);
                        }
                        .favorite {
                            svg {
                                @include color($mediumGray);
                                &:hover {
                                    @include color($violet);
                                }
                            }
                        }
                        .favorite-fill {
                            svg {
                                @include color($violet);
                            }
                        }
                    }
                    div:last-child {
                        @include display-flex();
                        @include direction($row);
                        @include align($center);
                        @include margin($v15 $v0);
                        @include justify($right);
                        > *:not(:last-child) {
                            @include margin($v0 $v10 $v0 $v0);
                            @include font-size($fs20);
                            @include font-weight($fwSemiBold);
                        }
                        > *:last-child {
                            @include margin($v0);
                            @include padding($v4 $v10);
                            @include border-radius($v4);
                            @include color($white);
                            @include font-size($fs18);
                            @include font-weight($fwMedium);
                            svg {
                                @include margin($v0 $v5 $v0 $v0);
                            }
                        }
                    }
                }
                .details {
                    > p {
                        @include margin($v0 $v0 $v5 $v0);
                        @include font-size($fs14);
                        @include color($darkGray);
                        @include font-weight($fwSemiBold);
                    }
                    .price-stats {
                        @include display-flex();
                        @include direction($row);
                        @include justify($justify-between);
                        > div {
                            p {
                                @include font-size($fs14);
                                @include color($darkGray);
                                @include font-weight($fwSemiBold);
                                @include margin($v0);
                                span {
                                    @include margin($v0 $v0 $v0 $v5);
                                }
                            }
                            p:last-child {
                                @include margin($v5 $v0 $v0);
                            }
                        }
                        select {
                            @extend .button-svg;
                            @include margin($v0 $v0 $v0 $v10);
                            @include padding($v5 $v0);
                            @include font-size($fs14);
                            @include outline-none();
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 841px) {
    .coin-banner {
        > div {
            .first {
                div {
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}
// Progress slider
// slider in the coin-banner componnet
.rc-slider {
    @include padding($v5 $v0 $v15 !important);
    .rc-slider-rail {
        @include background-color($lightBlue);
        @include row($v8);
        @include border-radius($v8);
        @include position($relative);
        @include display-block();
        @include column($cw100);
    }
    .rc-slider-track {
        @include background-color($violet);
        @include row($v8);
        @include border-radius($v8);
        @include position($absolute);
    }
    .rc-slider-track-after {
        @include position($absolute);
        bottom: -10px;
        @include column($v10);
        @include row($v10);
        @include background-color($violet);
        @include margin($v0 $v0 $v0 -5px);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
}
// navigation buttons
// the part after the coin-banner
.coinPageNav {
    @include padding($v0 $v60);
    > div {
        @include margin($v0 $auto);
        @include padding($v30);
        @include background-color($white);
        @include border-radius($v15);
        @include box-shadow($v0 $v0 $v10 #00000026);
        max-width: 1400px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        button:not(:last-child) {
            @include margin($v0 $v10 $v0 $v0);
        }
        button {
            @include padding($v8 $v15);
            @include border($v1 $solid $transparent);
            @include background-color($transparent);
            @include border-radius($v4);
            @include font-size($fs14);
            @include font-weight($fwMedium);
            @include color($black);
            &:hover {
                @include color($violet);
                @include border($v1 $solid $violet);
            }
            &.active {
                @include color($violet);
                @include border($v1 $solid $violet);
            }
        }
    }
}
// main section
//the part after the the coinPageNav
.coin-section {
    @include padding($v60);
    .first {
        @include margin($v0 $v0 $v30 $v0);
        @include padding($v30);
        @include container-max-width();
        @include background-color($white);
        @include border-radius($v15);
        .title {
            @include margin($v0 $v0 $v30 $v0);
            p {
                @include color($black);
                @include font-size($fs24);
                @include font-weight($fwBold);
                @include margin($v0);
            }
        }
        .chart {
            .recharts-wrapper {
                max-width: 1340px !important;
                width: 100% !important;
                height: auto !important;
                .recharts-surface {
                    width: 100% !important;
                    height: auto !important;
                }
                .recharts-tooltip-wrapper {
                    z-index: 1;
                }
            }
        }
        .time-range {
            .react_time_range__time_range_container {
                @include column($cw100);
                @include padding($v30 $v0 $v0);
            }
        }
    }
    .sub-section {
        @include display-flex();
        @include direction($row);
        max-width: 1400px;
        margin: auto;
        .coin-sidebar {
            @include padding($v60 $v0);
            @include column($fit);
            > div:not(:last-child) {
                @include margin($v0 $v0 $v60 $v0);
            }
        }
    }
}
// Chart component
// the part in the coin-section component
.chartNav {
    @include display-flex();
    @include direction($row);
    @include align($center);
    @include margin($v0 $v0 $v30 $v0);
    .left {
        @include margin($v0 $v30 $v0 $v0);
    }
    ul {
        @include list();
        @include display-flex();
        @include direction($row);
        @include align($center);
        @include padding($v8 $v15);
        @include border-radius($v4);
        @include background-color($lightGray);
        @include box-shadow($v3 $v3 $v5 #00000026);
        li {
            @include font-size($fs12);
            @include font-weight($fwSemiBold);
            @include padding($v4 $v10);
            @include border-radius($v4);
            &:not(:last-child) {
                @include margin($v0 $v5 $v0 $v0);
            }
            &.active {
                @include background-color($white);
                @include box-shadow($v1 $v1 $v2 #00000033);
            }
        }
        &.right {
            li:last-child {
                @include position($relative);
                @include margin($v0 $v0 $v0 $v5);
            }
            li:last-child:before {
                @include position($absolute);
                content: "";
                @include column($v1);
                @include row($cw100);
                @include background-color($mediumGray);
                left: -5px;
                top: $v0;
            }
        }
    }
}
// Historical data Component
// the part when we click on historical data in the coinPageNav component
.historical-data {
    @include padding($v60);
    .layout {
        @include container-max-width();
        @extend .container-white;
        .table-sub {
            @include color($black);
            @include font-size($fs14);
            @include line($fs20);
            @include font-weight($fwSemiBold);
            @include margin($v0);
        }
        .title {
            @include color($black);
            @include font-size($fs24);
            @include font-weight($fwBold);
            @include margin($v0 $v0 $v30 $v0);
        }
        .box-table {
            @include column($cw100);
            table-layout: auto;
            overflow-x: auto;
            @include margin($v0 $v0 $v30 $v0);
            thead {
                @include color($black);
                @include font-size($fs14);
                @include text($center);
                tr {
                    border-top-left-radius: $v10;
                    border-bottom-left-radius: $v10;
                    @include box-shadow($v0 $v3 $v6 #00000033);
                    border-top-right-radius: $v10;
                    border-bottom-right-radius: $v10;
                    th {
                        @include background-color($lightGray);
                        @include padding($v15 $v5);
                        @include font-weight($fwSemiBold);
                        @include font-size($fs12);
                    }
                    th:first-child {
                        border-top-left-radius: $v10;
                        border-bottom-left-radius: $v10;
                    }
                    th:last-child {
                        border-top-right-radius: $v10;
                        border-bottom-right-radius: $v10;
                    }
                    th:nth-last-child(-n + 6) {
                        @include border-left($v1 $solid #c3c5c870);
                    }
                }
            }
            tbody {
                tr:nth-child(even) {
                    @include background-color(#f2f5f859);
                }
                tr {
                    @include border-bottom($v1 $solid #c3c5c870);
                    td {
                        @include padding($v15 $v5);
                        @include font-weight($fwSemiBold);
                        @include font-size($fs12);
                        @include color($black);
                        white-space: nowrap;
                    }
                    td:nth-last-child(-n + 6) {
                        @include border-left($v1 $solid #c3c5c870);
                        @include text($center);
                    }
                    td:nth-child(-n + 2) {
                        @include text($center);
                    }
                    td:nth-child(-n + 3) {
                        left: 0;
                    }
                }
            }
        }
    }
}
// Coin about component
// the part where are displayed information about the coin
.about-coin {
    @include column(calc(100% - 420px - $v60));
    @include margin($v0 $v60 $v0 $v0);
    .about {
        @include margin($v60 $v0 $v60 $v0);
        @extend .container-white;
        h1 {
            @include color($black);
            @include font-size($fs24);
            @include font-weight($fwBold);
            @include margin($v0 $v0 $v15 $v0);
        }
        h2 {
            @include color($darkGray);
            @include font-size($fs22);
            @include font-weight($fwSemiBold);
            @include margin($v0 $v0 $v15 $v0);
        }
        h3 {
            @include color($violet);
            @include font-size($fs20);
            @include font-weight($fwSemiBold);
            @include margin($v0 $v0 $v15 $v0);
        }
        h4 {
            @include color($darkGray);
            @include font-size($fs20);
            @include font-weight($fwSemiBold);
            @include margin($v0 $v0 $v15 $v0);
        }
        p {
            @include color($darkGray);
            @include font-size($fs14);
            @include font-weight($fwRegular);
            @include margin($v0 $v0 $v15 $v0);
            @include line(20px);
        }
        ul {
            @include margin($v0 $v0 $v10 $v0);
            li {
                @include color($darkGray);
                @include font-size($fs14);
                @include line(20px);
                @include font-weight($fwMedium);
            }
            ::marker {
                @include color($violet !important);
            }
        }
        ol {
            @include margin($v0 $v0 $v15 $v0);
            li {
                @include color($darkGray);
                @include font-size($fs14);
                @include line(20px);
                @include font-weight($fwMedium);
            }
            ::marker {
                @include color($violet !important);
            }
        }
        a {
            @include color($violet);
            @include decoration($none);
            @include font-size($fs14);
            @include font-weight($fwMedium);
            &:hover {
                @include color($lightBlue);
            }
        }
    }
    .like-dislike {
        @include column($cw100);
        @include padding($v30);
        @include border-radius($v15);
        @include box-shadow(1px 2px 4px rgb(0 0 0 / 15%));
        @include image-url("../assets/Images/star-ratings.png");
        @include background-image-center();
        background-size: unset;
        background-position: center bottom;
        @include background-color($white);
        @include display-flex();
        @include direction($row);
        @include align($center);
        @include justify($justify-between);
        @include margin($v60 $v0);
        > div:first-child {
            p {
                @include margin($v0);
            }
            p:first-child {
                @include color($black);
                @include font-size($fs24);
                @include font-weight($fwBold);
            }
            p:last-child {
                @include color($gray);
                @include font-size($fs16);
                @include line($fs24);
                @include font-weight($fwSemiBold);
            }
        }
        > div:last-child {
            @include flex($o1);
            @include margin($v0 $v0 $v0 $v20);
            @include display-flex();
            @include justify($right);
            button {
                @include column(120px);
                @extend .button-svg;
                @include border($v1 $solid $violet);
                @include color($violet);
                @include font-size($fs18);
                @include font-weight($fwMedium);
                @include hover-scale();
                &:hover {
                    @include scale();
                }
                span {
                    @include margin($v0 $v0 $v0 $v15);
                    @include font-weight($fwSemiBold);
                }
            }
            button:last-child {
                @include margin($v0 $v0 $v0 $v30);
            }
        }
    }
    .Analysis {
        @include column($cw100);
        @include padding($v30);
        @include border-radius($v15);
        @include box-shadow(1px 2px 4px rgb(0 0 0 / 15%));
        @include image-url("../assets/Images/analysis.png");
        @include background-image-center();
        background-size: unset;
        background-position: center center;
        @include background-color($white);
        @include display-flex();
        @include direction($row);
        @include align($center);
        @include justify($justify-between);
        @include margin($v60 $v0);
        > div:first-child {
            p {
                @include margin($v0);
                @include color($black);
                @include font-size($fs24);
                @include font-weight($fwBold);
            }
        }
        > div:last-child {
            @include flex($o1);
            @include margin($v0 $v0 $v0 $v20);
            @include display-flex();
            @include justify($right);
            button {
                @extend .button-svg;
                @include border($v1 $solid $violet);
                @include color($violet);
                @include font-size($fs18);
                @include font-weight($fwMedium);
                @include hover-scale();
                &:hover {
                    @include scale();
                }
            }
        }
    }
}
// Watchlist Component
// the part after the infromation about the coin
.watch-list {
    @include column($cw100);
    .title {
        @include margin($v0 $v0 $v30 $v0);
        @include color($black);
        @include font-size($fs24);
        @include font-weight($fwBold);
    }
    .list {
        @include list();
        @include display-flex();
        @include direction($row);
        @include margin(-30px);
        // .list-item:not(:last-child) {
        //     @include margin($v0 $v60 $v0 $v0);
        // }
        .list-item {
            @include column(calc($cw20 - $v60));
            @include padding($v30);
            @include border-radius($v15);
            @include box-shadow(0px 1px 2px 0px rgb(0 0 0 / 25%));
            @include background-color($white);
            @include margin($v30);
            a {
                @include decoration($none);
                .coin {
                    @include display-flex();
                    @include direction($row);
                    @include margin($v0 $v0 $v30 $v0);
                    .coin-image {
                        @include margin($v0 $v30 $v0 $v0);
                        img {
                            @include column($fs35);
                        }
                    }
                    .coin-details {
                        @include flex($o1);
                        p:not(:last-child) {
                            @include margin($v0 $v0 $v2 $v0);
                        }
                        p:last-child {
                            @include margin($v0);
                        }
                        .name {
                            @include font-size($fs16);
                            @include font-weight($fwSemiBold);
                            @include color($gray);
                        }
                        .price {
                            @include font-size($fs16);
                            @include font-weight($fwBold);
                            @include color($black);
                        }
                        .percent {
                            @include font-size($fs14);
                            @include border-radius($v4);
                            @include font-weight($fwSemiBold);
                            @include padding($v0 $v7);
                            @include line($fs24);
                            @include column($fit);
                            @include color($white);
                        }
                    }
                }
                .coin-chart {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
//the part on the righ on the page
.coin-sidebar {
    .converter {
        @include column(420px);
        .converter-title {
            @include font-size($fs16);
            @include font-weight($fwBold);
            @include color($black);
            @include margin($v0 $v0 $v15 $v0);
        }
        .converter-holder {
            @include background-color($white);
            @include box-shadow($v2 $v5 $v15 #00000026);
            @include border-radius($v15);
            > div {
                @include padding($v30);
                @include display-flex();
                @include direction($row);
                @include align($center);
                div:not(:last-child) {
                    @include margin($v0 $v15 $v0 $v0);
                }
                .crypto-img {
                    @include column($fit);
                    img {
                        @include column($fs35);
                    }
                }
                .name {
                    @include flex($o1);
                    p {
                        @include margin($v0);
                    }
                    p:first-child {
                        @include font-size($fs12);
                        @include font-weight($fwSemiBold);
                        @include color($gray);
                    }
                    p:last-child {
                        @include font-size($fs14);
                        @include font-weight($fwSemiBold);
                        @include color($black);
                    }
                }
                div:last-child {
                    @include flex($o1);
                    input {
                        @include column($cw100);
                        @include text($right);
                        @include border($none);
                        @include color($black);
                        @include font-weight($fwBold);
                        @include font-size($fs16);
                        @include outline-none();
                        @include background-color($transparent);
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    /* Firefox */
                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }
            }
            > div:last-child {
                @include border-radius($v0 $v0 $v15 $v15);
            }
        }
    }
    .Coin-statistics {
        @include column(420px);
        @include border-radius($v15);
        @include box-shadow(1px 2px 4px rgb(0 0 0 / 15%));
        @include background-color($white);
        @include padding($v30);
        .main-title {
            @include font-weight($fwBold);
            @include font-size($fs16);
            @include color($black);
            @include margin($v0 $v0 $v15 $v0);
        }
        .items {
            .show-more {
                @include background-color($white !important);
                > div:nth-last-child(2) {
                    @include opacity($o1);
                }
                > div:last-child {
                    @include padding($v10 $v0);
                    button {
                        @extend .button-svg;
                        @include border($v1 $solid $violet);
                        @include color($violet);
                        @include font-size($fs18);
                        @include font-weight($fwMedium);
                        @include hover-scale();
                        &:hover {
                            @include scale();
                        }
                    }
                }
            }
            .items-group {
                .items-title {
                    @include padding($v5 $v0);
                    @include margin($v0);
                    @include color($violet);
                    @include font-size($fs14);
                    @include line(20px);
                    @include font-weight($fwBold);
                }
                .item {
                    @include display-flex();
                    @include direction($row);
                    @include justify($justify-between);
                    @include padding($v10 $v0);
                    p {
                        @include margin($v0);
                    }
                    p:first-child {
                        @include color($gray);
                        @include font-size($fs14);
                        @include line(20px);
                        @include font-weight($fwSemiBold);
                        svg {
                            @include margin($v0 $v0 $v0 $v3);
                        }
                        span:last-child {
                            display: block;
                        }
                    }
                    p:last-child {
                        @include color($black);
                        @include font-size($fs14);
                        @include line(20px);
                        @include font-weight($fwBold);
                        span:last-child {
                            display: block;
                            @include text($right);
                        }
                        .sep {
                            @include margin($v0 $v0 $v0 $v2);
                        }
                    }
                }
                > :not(:last-child) {
                    @include border-bottom($v1 $solid $mediumGray);
                }
            }
        }
    }
}
.tab-box {
    @include column(420px);
    @extend .container-white;
    .nav-tabs {
        @include border($none);
        @include justify($justify-between);
        .nav-link {
            @include color($mediumGray);
            @include font-size($fs14);
            @include border($v0 $solid $transparent !important);
            @include padding($v0 $v0 $v15);
            @include border-radius($v0);
            @include uppercase();
            @include font-weight($fwSemiBold);
        }
        :hover {
            @include color($violet);
        }
        .nav-link.active {
            @include color($violet);
            @include font-weight($fwBold);
        }
    }
    .tab-content {
        @include padding($v10 $v0 $v0);
        .tab-pane {
            .items-holder {
                .item {
                    @include padding(7.5px $v0);
                }
                .item {
                    @include display-flex();
                    @include align($center);
                    @include direction($row);
                    @include border-bottom($v1 $solid $mediumGray);
                    .item-logo {
                        @include column($v25);
                        @include margin($v0 $v10 $v0 $v0);
                        img {
                            @include column($cw100);
                            @include row($auto);
                        }
                    }
                    .holder {
                        width: calc(100% - 120px);
                        @include display-flex();
                        @include align($end);
                        p {
                            display: inline;
                            @include margin($v0);
                        }
                        a {
                            @include decoration($none);
                            @include font-size($fs14);
                            @include font-weight($fwBold);
                            @include color($black);
                            &:hover {
                                @include color($violet);
                            }
                            .item-title {
                                @include margin($v0 $v10 $v0 $v0);
                            }
                        }
                        .item-symbol {
                            @include font-size($fs12);
                            @include color($gray);
                            @include font-weight($fwBold);
                        }
                    }
                    .item-percent {
                        @include margin($v0 $v0 $v0 $auto);
                        p {
                            @include background-color($darkGray);
                            @include border-radius($v4);
                            @include padding($v5 $v8);
                            @include font-size($fs12);
                            @include font-weight($fwMedium);
                            @include margin($v0);
                            @include color($white);
                            > svg {
                                @include margin($v0 $v4 $v0 $v0);
                            }
                        }
                    }
                }
            }
        }
    }
    .more {
        @include display-flex();
        @include justify($right);
        @include margin($v15 $v0 $v0 $v0);
        :hover {
            @include color($violet);
        }
        a {
            @include decoration($none);
            @include font-size($fs14);
            @include font-weight($fwBold);
            @include color($mediumGray);
            span:first-child {
                @include margin($v0 $v3 $v0 $v0);
            }
            span {
                svg {
                    @include font-size($fs16);
                    vertical-align: -3px;
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .coin-section {
        padding: 30px;
        .first {
            @include margin($v0 $v0 $v30 $v0);
            @include padding($v30);
            @include container-max-width();
            @include background-color($white);
            @include border-radius($v15);
            .title {
                @include margin($v0 $v0 $v30 $v0);
                p {
                    @include color($black);
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                    @include margin($v0);
                }
            }
            .chart {
                .recharts-wrapper {
                    max-width: 1340px !important;
                    width: 100% !important;
                    height: auto !important;
                    .recharts-surface {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
            .time-range {
                .react_time_range__time_range_container {
                    @include column($cw100);
                    @include padding($v30 $v0 $v0);
                }
            }
        }
        .about-coin {
            width: 100%;
            margin-right: 0px;
        }
        .sub-section {
            @include display-flex();
            @include direction($row);
            .coin-sidebar {
                @include padding($v60 $v0);
                @include column($fit);
                width: 100%;
                display: flex;
                flex-direction: column;
                > div:not(:last-child) {
                    @include margin($v0 $v0 $v60 $v0);
                }
                .converter {
                    width: 100%;
                }
                .Coin-statistics {
                    width: 100%;
                }
                .tab-box {
                    width: 100%;
                }
            }
            .watch-list {
                @include column($cw100);
                .title {
                    @include margin($v0 $v0 $v30 $v0);
                    @include color($black);
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                }
                .list {
                    @include list();
                    @include display-flex();
                    @include direction($row);
                    @include margin(-30px);
                    justify-content: space-around;
                    // .list-item:not(:last-child) {
                    //     @include margin($v0 $v60 $v0 $v0);
                    // }
                    .list-item {
                        @include column(calc($cw20 - $v60));
                        @include padding($v30);
                        @include border-radius($v15);
                        @include box-shadow($v1 $v3 $v5 #00000040);
                        @include background-color($white);
                        @include margin($v30);
                        width: 25%;
                        a {
                            @include decoration($none);
                            .coin {
                                @include display-flex();
                                @include direction($row);
                                @include margin($v0 $v0 $v30 $v0);
                                .coin-image {
                                    @include margin($v0 $v30 $v0 $v0);
                                    img {
                                        @include column($fs35);
                                    }
                                }
                                .coin-details {
                                    @include flex($o1);
                                    p:not(:last-child) {
                                        @include margin($v0 $v0 $v2 $v0);
                                    }
                                    p:last-child {
                                        @include margin($v0);
                                    }
                                    .name {
                                        @include font-size($fs16);
                                        @include font-weight($fwSemiBold);
                                        @include color($gray);
                                    }
                                    .price {
                                        @include font-size($fs16);
                                        @include font-weight($fwBold);
                                        @include color($black);
                                    }
                                    .percent {
                                        @include font-size($fs14);
                                        @include border-radius($v4);
                                        @include font-weight($fwSemiBold);
                                        @include padding($v0 $v7);
                                        @include line($fs24);
                                        @include column($fit);
                                        @include color($white);
                                    }
                                }
                            }
                            .coin-chart {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .coin-banner {
        padding: 30px;
        > div {
            .coin-details {
                .info-holder {
                    width: 100%;
                    .stats-info {
                        > div {
                            width: 50%;
                        }
                    }
                }
                .price-info {
                    width: 100%;
                }
            }
        }
    }
    .coinPageNav {
        padding: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .coin-section {
        padding: 30px;
        .first {
            @include margin($v0 $v0 $v30 $v0);
            @include padding($v30);
            @include container-max-width();
            @include background-color($white);
            @include border-radius($v15);
            .title {
                @include margin($v0 $v0 $v30 $v0);
                p {
                    @include color($black);
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                    @include margin($v0);
                }
            }
            .chart {
                .recharts-wrapper {
                    max-width: 1340px !important;
                    width: 100% !important;
                    height: auto !important;
                    .recharts-surface {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
            .time-range {
                .react_time_range__time_range_container {
                    @include column($cw100);
                    @include padding($v30 $v0 $v0);
                }
            }
        }
        .about-coin {
            width: 100%;
            margin-right: 0px;
        }
        .sub-section {
            @include display-flex();
            @include direction($row);
            .coin-sidebar {
                width: 100% !important;
                @include padding($v60 $v0);
                @include column($fit);
                > div:not(:last-child) {
                    @include margin($v0 $v0 $v60 $v0);
                }
                .converter {
                    width: 100%;
                }
                .Coin-statistics {
                    width: 100%;
                }
                .tab-box {
                    width: 100%;
                }
            }
            .watch-list {
                @include column($cw100);
                .title {
                    @include margin($v0 $v0 $v30 $v0);
                    @include color($black);
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                }
                .list {
                    @include list();
                    @include display-flex();
                    @include direction($row);
                    @include margin(-30px);
                    justify-content: space-around;
                    // .list-item:not(:last-child) {
                    //     @include margin($v0 $v60 $v0 $v0);
                    // }
                    .list-item {
                        @include column(calc($cw20 - $v60));
                        @include padding($v30);
                        @include border-radius($v15);
                        @include box-shadow($v1 $v3 $v5 #00000040);
                        @include background-color($white);
                        @include margin($v30);
                        width: 40%;
                        a {
                            @include decoration($none);
                            .coin {
                                @include display-flex();
                                @include direction($row);
                                @include margin($v0 $v0 $v30 $v0);
                                .coin-image {
                                    @include margin($v0 $v30 $v0 $v0);
                                    img {
                                        @include column($fs35);
                                    }
                                }
                                .coin-details {
                                    @include flex($o1);
                                    p:not(:last-child) {
                                        @include margin($v0 $v0 $v2 $v0);
                                    }
                                    p:last-child {
                                        @include margin($v0);
                                    }
                                    .name {
                                        @include font-size($fs16);
                                        @include font-weight($fwSemiBold);
                                        @include color($gray);
                                    }
                                    .price {
                                        @include font-size($fs16);
                                        @include font-weight($fwBold);
                                        @include color($black);
                                    }
                                    .percent {
                                        @include font-size($fs14);
                                        @include border-radius($v4);
                                        @include font-weight($fwSemiBold);
                                        @include padding($v0 $v7);
                                        @include line($fs24);
                                        @include column($fit);
                                        @include color($white);
                                    }
                                }
                            }
                            .coin-chart {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .coin-banner {
        padding: 30px;
        > div {
            .coin-details {
                .info-holder {
                    width: 100%;
                    .stats-info {
                        > div {
                            width: 50%;
                        }
                    }
                }
            }
            .price-info {
                width: 100% !important;
            }
        }
    }
    .coinPageNav {
        padding: 30px;
    }
}
// Hide scrollbar for Chrome, Safari and Opera /
.no-rule::-webkit-scrollbar {
    display: none;
}

// Hide scrollbar for IE, Edge and Firefox /
.no-rule {
    -ms-overflow-style: none; // IE and Edge /
    scrollbar-width: none; // Firefox /
}

// BLOG PAGE GOMPONENTS
.blog {
    @include container-padding();
    .main-container {
        @include container-max-width();
        .heading {
            h1 {
                @include margin($v0 $v0 $v30);
                @include font-size($fs24);
                @include font-weight($fwBold);
            }
        }
        .blog-categories {
            @include margin(-7px);
            @include display-flex();
            @include direction($row);
            @include padding($v0 $v0 $v15 $v0);
            button {
                @extend .button-svg;
                @include margin($v7);
                &.active {
                    @include color($violet);
                    @include border($v1 $solid $violet);
                }
            }
        }
        .blog-post-listing {
            @include display-flex();
            @include direction($row);
            @include align($stretch);
            @include justify($start);
            @include margin(-15px);
            .blog-post {
                @include column(calc($cw25 - $v30));
                @include margin($v15);
                @include background-color($white);
                @include border-radius($v8);
                @include display-flex();
                flex-wrap: nowrap;
                @include direction($column);
                @include align($stretch);
                @include justify($justify-between);
                overflow: hidden;
                @include box-shadow(3px 2px 4px 0px #bbbbbb);
                .image-wrap {
                    height: 250px;
                    img {
                        @include column($cw100);
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .post-content {
                    @include display-flex();
                    @include direction($column);
                    @include align($stretch);
                    @include flex($o1);
                    @include padding($v15);
                    > *:not(:last-child) {
                        @include margin($v0 $v0 $v15 $v0);
                    }
                    a{
                        text-decoration: none;
                        h3 {
                        @include font-size($fs16);
                        @include line($fs20);
                        @include font-weight($fwSemiBold);
                        @include color($dark);
                        @include margin($v0);
                    }
                }
                    p {
                        @include font-size($fs14);
                        @include line($fs18);
                        @include font-weight($fwRegular);
                        @include color($mediumBlue);
                        @include margin($v0);
                    }
                    .see-more {
                        @include flex($o1);
                        @include display-flex();
                        @include align($end);
                        @include justify($end);
                        @include decoration($none);
                        @include font-size($fs12);
                        @include font-weight($fwSemiBold);
                        @include color($dark);
                        @include text($right);
                        @include margin($v0);
                        &:hover {
                            @include color($violet);
                        }
                    }
                }
                .post-meta {
                    @include column($cw100);
                    @include padding($v15);
                    @include border-top($v1 $solid $lightBlue);
                    p {
                        @include margin($v0);
                        @include font-size($fs12);
                        @include color($lightBlue);
                        @include font-weight($fwSemiBold);
                    }
                }
            }
        }
    }
}

// BLOG RESPONSIVE
@media only screen and (min-width: 900px) and (max-width: 1199px) {
    .blog {
        .main-container {
            .blog-post-listing {
                .blog-post {
                    @include column(calc($cw33 - $v30));
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 899px) {
    .blog {
        .main-container {
            .blog-post-listing {
                .blog-post {
                    @include column(calc($cw50 - $v30));
                }
            }
        }
    }
}
@media only screen and (max-width: 599px) {
    .blog {
        .main-container {
            .blog-post-listing {
                .blog-post {
                    @include column(calc($cw100 - $v30));
                }
            }
        }
    }
}

// ADD POST
.add-post {
    .main-container {
        @include container-padding();
        .add-post-form {
            @include container-max-width;
            .title {
                h1 {
                    @include margin($v0 $v0 $v30);
                    @include font-size($fs24);
                    @include font-weight($fwBold);
                }
            }
            .form {
                > *:not(:last-child) {
                    @include margin($v0 $v0 $v15 $v0);
                }
                .form-group {
                    @include display-flex();
                    @include direction($column);
                    > *:not(:last-child) {
                        @include margin($v0 $v0 $v5 $v0);
                    }
                    label {
                        @include font-size($fs16);
                        @include font-weight($fwSemiBold);
                        span {
                            @include color($violet);
                            @include margin($v0 $v0 $v0 $v2);
                        }
                    }
                    input {
                        @include font-size($fs14);
                        @include font-weight($fwMedium);
                    }
                    textarea {
                        min-height: 130px;
                        max-height: 130px;
                        @include font-size($fs14);
                        @include font-weight($fwMedium);
                    }
                    input[name="categoryId"] {
                        @include column($v60);
                        @include font-size($fs14);
                        @include font-weight($fwMedium);
                    }
                }
                .footer {
                    .submit {
                        @include text($right);
                        button {
                            @extend .button-violet;
                            @include padding($v10 $v25);
                            @include font-size($fs14);
                            @include font-weight($fwMedium);
                        }
                    }
                }
            }
        }
    }
}

// SINGLE BLOG POST
.single-blog-post {
    @include container-padding();
    .main-container {
        @include container-max-width();
        @include display-flex();
        @include direction($row);
        @include align($start);
        @include justify($justify-between);
        .post {
            @include column(calc($cw100 - 300px - $p5));
            .title {
                h1 {
                    @include margin($v0 $v0 $v15);
                    @include font-size($fs24);
                    @include line($fs30);
                    @include font-weight($fwBold);
                }
            }
            .post-meta {
                p {
                    @include margin($v0 $v0 $v15);
                    @include font-size($fs12);
                    @include color($darkGray);
                    @include font-weight($fwSemiBold);
                }
            }
            .post-image {
                img {
                    @include column($cw100);
                    @include margin($v0 $v0 $v15);
                    @include row(450px);
                    object-fit: cover;
                    object-position: center center;
                }
            }
            .post-content {
                > *:not(:last-child) {
                    @include margin($v0 $v0 $v15);
                }
                h1 {
                    @include font-size($fs24);
                    @include line($fs30);
                    @include font-weight($fwBold);
                }
                h2 {
                    @include font-size($fs22);
                    @include line($fs28);
                    @include font-weight($fwBold);
                }
                h3 {
                    @include font-size($fs20);
                    @include line($fs26);
                    @include font-weight($fwSemiBold);
                }
                h4 {
                    @include font-size($fs18);
                    @include line($fs24);
                    @include font-weight($fwSemiBold);
                }
                h5 {
                    @include font-size($fs18);
                    @include line($fs24);
                    @include font-weight($fwMedium);
                }
                h6 {
                    @include font-size($fs18);
                    @include line($fs24);
                    @include font-weight($fwRegular);
                }
                p,
                li,
                a {
                    @include font-size($fs14);
                    @include line($fs24);
                    @include font-weight($fwMedium);
                }
                li::marker {
                    @include color($violet);
                    @include font-weight($fwSemiBold);
                }
                a {
                    @include color($violet);
                    @include decoration($none);
                    &:hover {
                        @include color($lightBlue);
                    }
                }
                blockquote {
                    p {
                        @include color($mediumBlue);
                        @include border-left($v5 $solid $violet);
                        @include padding($v15 $v0 $v15 $v15);
                    }
                }
                div {
                    figure {
                        // @include row($auto !important);
                        // @include column($auto !important);
                        // @include padding($v0 !important);
                        // iframe {
                        //     @include row($auto !important);
                        //     @include column($auto !important);
                        // }
                        img {
                            @include column($cw100);
                        }
                    }
                }
            }
        }
        .post-sidebar {
            @include column(300px);
        }
    }
}

//portfolio component
.portfolio-page {
    @include padding($v60);
    .breadcrumbs {
        @include container-max-width();
        @include padding($v0 $v0 $v40 $v0);
        .link {
            @include margin($v0);
            @include color($black);
            @include opacity(0.6);
            @include font-weight($fwMedium);
            @include decoration($none);
            &:hover {
                @include color($violet);
            }
        }
        .heading {
            @include margin($v0);
            @include font-weight($fwMedium);
        }
    }
    .portfolio-content {
        @include container-max-width();
        @include display-flex();
        @include direction($row);
        @include column($cw100);
        @include justify($justify-between);
        @include align($start);
        .coinBox {
            @include column(304px);
            @include row(409px);
            overflow-y: scroll;
            @include padding($v30);
            @include background-color($white);
            @include border-radius($v15);
            .head {
                h3 {
                    font-size: 20px;
                    margin: 0px;
                }
                span {
                    font-size: 23px;
                    margin-left: 5px;
                }
                @include display-flex();
                @include direction($row);
                @include margin($v0 $v0 $v20 $v0);
                align-items: center;
                input {
                    flex: 1;
                }
            }
            .no-rule {
                @include pointer();
                > *:not(:last-child) {
                    @include margin($v0 $v0 $v5 $v0);
                }
                > * {
                    .logo {
                        @include column($fit);
                        padding-right: 5px;
                        img {
                            @include column($v40);
                        }
                    }
                }
                > div {
                    span {
                        padding-right: 5px !important;
                    }
                }
                .coin {
                    .button {
                        @include display-flex();
                        padding: 0px;
                        justify-content: space-between;
                        margin: 12px 0px 20px 0px;
                        .sell {
                            color: #6614e1;
                            padding: 8px 10px;
                            margin: 0;
                            border: 1px solid #6614e1;
                            border-radius: 6px;
                            text-decoration: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 600;
                            background-color: transparent;
                            width: 40%;
                        }

                        .buy {
                            color: #6614e1;
                            padding: 8px 10px;
                            margin: 0;
                            border: 1px solid #6614e1;
                            border-radius: 6px;
                            text-decoration: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 600;
                            background-color: transparent;
                            width: 40%;
                        }
                    }
                    .sign-up-form {
                        display: flex;
                        flex-direction: column;
                        input {
                            width: 100%;
                            margin-top: 10px;
                        }
                    }

                    .submit {
                        > button {
                            color: #6614e1;
                            padding: 8px 10px;
                            margin: 0;
                            border: 1px solid #6614e1;
                            border-radius: 6px;
                            text-decoration: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 600;
                            background-color: transparent;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .portfolio-baner {
            @include column(calc($cw100 - 304px - 60px));
            @include border-radius($v15);
            .price {
                @include background-color($white);
                @include margin($v0 $v0 $v20 $v0);
                @include border-radius($v15);
                @include background-color($white);
                @include display-flex();
                @include justify(space-between);
                align-items: center;
                p:first-child {
                    padding-left: 30px;
                    @include font-size($fs24);
                }
                .row-right {
                    @include background-color($violet);
                    width: 50%;
                    clip-path: polygon(50px 0, 100% 0, 100% 100%, 0% 100%);
                    @include padding(30px 30px 30px 80px);
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    padding-left: 99px;
                    .amount {
                        @include font-size($fs24);
                        @include color($lightGray);
                    }
                    .account {
                        @include font-size($fs14);
                        @include color($lightBlue);
                    }
                }
            }
            .asset {
                @include background-color($white);
                @include padding($v30);
                @include column(100%);
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
            .table-container {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                @include background-color($white);
                @include margin($v0 $v0 $v20 $v0);
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                @include padding($v0 $v30 $v30 $v30);
                .table {
                    @include column(100%);
                    table-layout: auto;
                    th {
                        text-align: center;
                    }
                    tbody {
                        border-style: none;
                        border: 0px solid white;
                        tr {
                            > td:last-child {
                                button {
                                    color: #6614e1;
                                    padding: 8px 10px;
                                    margin-right: 5px;
                                    border: 1px solid #6614e1;
                                    border-radius: 6px;
                                    text-decoration: none;
                                    font-size: 14px;
                                    line-height: 14px;
                                    font-weight: 600;
                                    background-color: transparent;
                                    width: 40%;
                                    svg {
                                        width: 100%;
                                    }
                                }
                            }
                            td {
                                button {
                                    @include padding($v5 $v7 $v5 $v7);
                                }
                            }
                        }
                    }
                }
                .assets-data {
                    @include background-color($white);
                    @include margin($v0 $v0 $v20 $v0);
                    thead {
                        tr {
                            th {
                                @include column($cw20);
                            }
                        }
                    }
                }
            }
            .allocation {
                @include background-color($white);
                @include padding($v30);
                @include column(100%);
                @include border-radius($v15);
            }
            .remove-confirmation-wraper {
                .remove-confirmation-general {
                    .button {
                        color: #6614e1;
                        padding: 8px 10px;
                        margin: 0;
                        margin-right: 10px;
                        border: 1px solid #6614e1;
                        border-radius: 6px;
                        text-decoration: none;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 600;
                        background-color: transparent;
                        width: 150px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 572px) {
    .portfolio-baner {
        @include column($cw100 !important);
        @include order($o1);
        .price {
            p:first-child {
                padding-left: 12px !important;
                @include font-size($fs24 !important);
                padding-top: 12px !important;
            }
            .row-right {
                @include background-color($violet);
                width: 100% !important;
                clip-path: polygon(0px 0, 100% 0, 100% 100%, 0% 100%) !important;
                @include padding(12px);
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
                padding-left: 12px !important;
                .amount {
                    @include font-size($fs24);
                    @include padding(0px !important);
                }
                .account {
                    @include font-size($fs14);
                    @include padding(0px !important);
                }
            }
        }
        .table-container {
            @include padding(0px 12px 12px 12px);
        }
        .asset {
            @include padding(12px !important);
        }
        .allocation {
            @include padding(12px !important);
        }
    }
    .coinBox {
        @include order($o2);
        @include column($cw100);
        @include margin($v20 $v0 $v0 $v0);
        overflow-x: scroll;
        .head {
            @include display-flex();
            @include direction($row);
            input {
                flex: 1;
                @include border-radius($v5);
            }
        }
        .no-rule {
            @include row(200px);
            @include align($center);
            @include justify($justify-between);
            > * {
                @include column(230px);
                > *:not(:last-child) {
                    @include margin($v0 $v5 $v0 $v0);
                }
                .logo {
                    @include column($fit);
                    img {
                        @include column($v40);
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .portfolio-baner {
        @include column($cw100 !important);
        @include order($o1);
    }
    .coinBox {
        @include order($o2);
        @include column($cw100);
        @include margin($v20 $v0 $v0 $v0);
        overflow-x: scroll;
        .head {
            @include display-flex();
            @include direction($row);
            input {
                flex: 1;
                @include border-radius($v5);
            }
        }
        .no-rule {
            @include row(200px);
            @include align($center);
            @include justify($justify-between);
            > * {
                @include column(230px);
                > *:not(:last-child) {
                    @include margin($v0 $v5 $v0 $v0);
                }
                .logo {
                    @include column($fit);
                    img {
                        @include column($v40);
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .portfolio-page {
        padding: 15px;
        .portfolio-baner {
            @include column($cw100 !important);
            @include order($o1);
            .price {
                p:first-child {
                    padding-left: 30px;
                    @include font-size($fs24);
                }
                .row-right {
                    @include background-color($violet);
                    width: 50%;
                    clip-path: polygon(50px 0, 100% 0, 100% 100%, 0% 100%);
                    padding-left: 50px !important;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;

                    .amount {
                        @include font-size($fs24);
                        @include color($lightGray);
                    }
                    .account {
                        @include font-size($fs14);
                        @include color($lightBlue);
                    }
                }
            }
        }
        .coinBox {
            @include order($o2);
            @include column($cw100);
            @include margin($v20 $v0 $v0 $v0);
            overflow-x: scroll;
            .head {
                @include display-flex();
                @include direction($row);
                input {
                    flex: 1;
                    @include border-radius($v5);
                }
            }
            .no-rule {
                @include row(200px);
                @include align($center);
                @include justify($justify-between);
                > * {
                    white-space: nowrap;
                    @include column(230px);
                    > *:not(:last-child) {
                        @include margin($v0 $v5 $v0 $v0);
                    }
                    .logo {
                        @include column($fit);
                        img {
                            @include column($v40);
                        }
                    }
                }
            }
        }
    }
}

// CONTACT
.contact-page {
    background: #f9fcff;
    padding: 60px;
    .contact-wrapper {
        padding: 10px;
        margin: 0px auto;
        max-width: 1400px;
        .heading {
            text-align: center;
            padding: 0 0 40px 0;
            font-size: 50px;
            font-weight: 700;
        }
        .form-wraper {
            display: flex;
            .form {
                background-color: #ffffff;
                height: fit-content;
                width: calc(50% - 2.5%);
                margin-right: 2.5%;
                padding: 30px;
                box-shadow: 0px 0px 20px #00000026;
                border-radius: 10px;
                .with-padding {
                    width: 50%;
                    height: 100px;
                    padding-right: 5px;
                    p {
                        font-weight: 700;
                        margin: 0 0 8px 0;
                        font-size: 13px;
                    }
                    input {
                        width: 100%;
                        box-shadow: inset 0px 0px 12px #00000026;
                        border-radius: 10px;
                        border: none;
                        height: 50px;
                    }
                }
                .without-padding {
                    width: 50%;
                    height: 100px;
                    p {
                        font-weight: 700;
                        margin: 0 0 8px 0;
                        font-size: 13px;
                    }
                    input {
                        width: 100%;
                        box-shadow: inset 0px 0px 12px #00000026;
                        border-radius: 10px;
                        border: none;
                        height: 50px;
                    }
                    textarea {
                        width: 100%;
                        box-shadow: inset 0px 0px 12px #00000026;
                        border-radius: 10px;
                        border: none;
                        height: 200px;
                        margin-bottom: 25px;
                    }
                }
                .input-styling {
                    width: 100%;
                    height: 50px;
                    font-size: 20px;
                    background-color: #6614e1;
                    color: #ffffff;
                    border: none;
                    box-shadow: inset 0px 0px 12px #00000026;
                    border-radius: 10px;
                }
            }
            .question {
                width: calc(50% - 2.5%);
                margin-left: 2.5%;
                text-align: left;
                .heading {
                    font-size: 40px;
                }
                .explanation {
                    width: 100%;
                    font-weight: 600;
                    font-size: 16px;
                    color: #000000;
                    opacity: 0.7;
                    margin-bottom: 5%;
                    text-align: center;
                }
                .accordion {
                    .card-style {
                        border: none;
                        margin-bottom: 30px;
                        .card-header {
                            background: rgb(249, 252, 255);
                            border: none;
                            padding: 0px;
                            padding-bottom: 8px;
                        }
                        .card-body {
                            background: rgb(249, 252, 255);
                            color: #000000;
                            padding: 0px;
                            border-bottom: 1px solid #707070;
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .contact-page {
        padding: 25px !important;
        .contact-wrapper {
            .form-wraper {
                display: flex;
                flex-wrap: wrap;
                .form {
                    width: 100%;
                    margin-right: 0px;
                }
                .question {
                    width: 100%;
                    padding: 0px;
                    padding-top: 40px;
                    margin-left: 0px;
                }
            }
        }
    }
}

//ABOUT
.about-wraper {
    background: #f9fcff;
    padding: 60px;
    max-width: 1400px;
    margin: auto;
    p {
        font-size: 14px !important;
    }
    .about-menu {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .home {
            margin-bottom: 0px;
            color: #000000;
            opacity: 0.6;
            font-weight: 500;
            margin-right: 5px;
            .home-part {
                font-size: 20px;
                margin: 0px 10px 0px 10px;
            }
        }
        .about {
            margin-left: 5px;
            margin-bottom: 0px;
            font-weight: 500;
        }
    }
    .first-section {
        display: flex;
        margin-top: 3%;
        margin-bottom: 3%;
        .first-part {
            width: 50%;
            .heading {
                font-size: 50px;
            }
            .paragraph {
                font-size: 20px;
                font-weight: 500;
                text-indent: 20px;
            }
            .button-about {
                color: white;
                background-color: #231f20;
                border: none;
                font-size: 20px;
                padding: 10px 20px 10px 20px;
                border-radius: 10px;
                font-weight: 600;
                margin-top: 10px;
            }
        }
        .second-part {
            width: 50%;
            display: flex;
            justify-content: center;
            .image {
                width: 73% !important;
                margin-top: -9%;
                object-fit: contain;
            }
        }
    }
    .second-section {
        border-top: 2px solid rgb(213 213 213);
        padding-bottom: 3%;
        .wraper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 70px;
            .heading {
                font-size: 50px;
                font-weight: 700;
                margin-bottom: 20px;
                margin-top: 40px;
            }
            .image {
                margin: 20px;
                width: 80%;
            }
            .text-wraper {
                width: 30%;
                .text {
                    font-weight: 700;
                    text-align: center;
                }
            }
        }
    }
    .third-section {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .wraper {
            .first-part {
                width: 200px;
                height: 200px;
                background-color: #f2f5f8;
                box-shadow: 0px 0px 10px #00000029;
                .div-wraper {
                    width: 200px;
                    height: 200px;
                    background-color: #f2f5f8;
                    box-shadow: 0px 0px 10px #00000029;
                    transform: rotate(45deg);
                    .image {
                        transform: rotate(-45deg);
                        margin: 25px;
                        width: 70%;
                    }
                }
            }
            .second-part {
                .heading {
                    font-weight: 700px;
                    text-align: center;
                    font-size: 25px;
                    margin-top: 53px;
                }
                .paragraph {
                    font-size: 25px;
                }
                .image-wraper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 10px;
                    margin-left: 5px;
                    .image-general {
                        width: 55px;
                        height: 55px;
                    }
                    .twitter {
                        width: 46px;
                        height: 46px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
    .about-wraper {
        padding: 30px;
        .first-section {
            flex-wrap: wrap;
            .first-part {
                width: 100%;
            }
            .second-part {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 12%;
                .image {
                    width: 50%;
                }
            }
        }
        .third-section {
            .wraper {
                width: 50%;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .about-wraper {
        .third-section {
            .wraper {
                width: 100%;
            }
        }
    }
}


.table-wraper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 23px;
    padding-right: 23px;
    .header {
        margin: 23px auto;
    }
    .table {
        display: flex;
        justify-content: space-around;
        margin: 23px auto;
        background-color: white;
        padding: 20px;
        width: fit-content;
        border-radius: 23px;
        .general-head {
            .table-head {
                border: 0px;
                padding-bottom: 50px;
                .table-head-content {
                    padding: 0px 120px 23px 0px;
                }
                .table-head-different {
                    padding: 0px 0px 23px 0px;
                }
            }
        }
        .table-body {
            .general-body {
                border: 0px;
                padding: 12px;
                .table-body-content {
                    padding-right: 120px;
                    padding-bottom: 20px;
                }
            }
            
        }
    }
}
.table-wraper-trending-currency {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 23px;
    padding-right: 23px;
    .header {
        margin: 23px auto;
    }
    .table {
        display: flex;
        justify-content: space-around;
        margin: 23px auto;
        background-color: white;
        padding: 20px;
        width: fit-content;
        border-radius: 23px;
        .general-head {
            .table-head {
                border: 0px;
                padding-bottom: 50px;
                .table-head-content {
                    padding: 0px 23px 23px 0px;
                }
                .table-head-different {
                    padding: 0px 0px 23px 0px;
                }
            }
        }
        .table-body {
            .general-body {
                border: 0px;
                padding: 12px;
                .table-body-content {
                    padding-right: 23px;
                    padding-bottom: 20px;
                }
            }
            
        }
    }
}
.table-wraper-most-currency {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 23px;
    padding-right: 23px;
    .header {
        margin: 23px auto;
    }
    .table {
        display: flex;
        justify-content: space-around;
        margin: 23px auto;
        background-color: white;
        padding: 20px;
        width: fit-content;
        border-radius: 23px;
        .general-head {
            .table-head {
                border: 0px;
                padding-bottom: 50px;
                .table-head-content {
                    padding: 0px 23px 23px 0px;
                }
                .table-head-different {
                    padding: 0px 0px 23px 0px;
                }
            }
        }
        .table-body {
            .general-body {
                border: 0px;
                padding: 12px;
                .table-body-content {
                    padding-right: 23px;
                    padding-bottom: 20px;
                }
            }
            
        }
    }
}

.faq-general {
    .third {
        @extend .section-max;
        @include direction($column);
        @include padding($v60 $v0);
        > div:first-child {
            @include background-color($white);
            @include border-radius($v15 $v15 $v0 $v0);
            p {
                @include font-size($fs24);
                @include font-weight($fwBold);
                @include margin($v0);
                @include padding($v30);
            }
        }
        .faq {
            @include display-flex();
            @include direction($row);
            @include border-top($v2 $solid $lightGray);
            @include border-radius($v0 $v0 $v15 $v15);
            // @include border-bottom($v2 $solid $lightGray);
            @include background-color($white);
            .nav.nav-tabs {
                @include column($cw50);
                @include order($o2);
                @include display-flex();
                @include direction($column);
                @include padding($v30);
                @include border-left($v1 $solid $lightGray);
                @include border-bottom($v0 $solid $transparent);
                .nav-item {
                    @include margin($v0 $v0 $v10 $v0);
                    button {
                        @include border($none);
                        @include padding($v0);
                        @include color($black);
                        @include font-size($fs16);
                        @include font-weight($fwMedium);
                        @include line(24px);
                        text-align: left;
                        &:hover {
                            @include color($violet);
                        }
                        > div {
                            @include display-flex();
                            @include direction($row);
                            svg {
                                @include margin($v0 $v10 $v0 $v0);
                                @include font-size($fs22);
                            }
                            span {
                                @include column(calc(100% - 32px));
                            }
                        }
                    }
                    button.active {
                        @include color($violet);
                    }
                    // button:not(:last-child) {
                    //     @include margin($v0 $v0 $v5 $v0);
                    // }
                }
            }
            .tab-content {
                @include column($cw50);
                @include order($o1);
                @include padding($v30);
                @include border-right($v1 $solid $lightGray);
                .title {
                    @include color($black);
                    @include font-size($fs22);
                    @include font-weight($fwSemiBold);
                }
                p {
                    @include color($black);
                    @include font-size($fs14);
                    @include line(26px);
                }
                p:not(:last-child) {
                    @include margin($v0 $v0 $v20 $v0);
                }
                p:last-child {
                    @include margin($v0);
                }
            }
        }
    }
}
