/* .App {
    text-align: center;
} */


/* LOADING SPINNER  */

.loading {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top: 10px solid #6614E1;
    border-bottom: 10px solid #6614E1;
    animation: rotate 1.8s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}